<template>
  <div>
    <div class="gva-search-box" style="margin-bottom: 0">
      <el-form :inline="true" :model="searchInfo" class="demo-form-inline" @keyup.enter="onSubmit">
        <el-form-item :label="t('cableManage.searchCable')">
          <el-input v-model.trim="searchInfo.name" />
        </el-form-item>

        <el-form-item :label="t('cableManage.searchCompany')" v-show="isAdmin">
          <el-select v-model="searchInfo.companyId" style="width: 240px" size="large">
            <el-option v-for="item in companyInfo" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" icon="search" @click="onSubmit">{{ t('cableManage.searchSubmit')
            }}</el-button>
          <el-button size="small" icon="refresh" @click="onReset">{{ t('cableManage.searchReset') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="gva-table-box">
      <div class="gva-btn-list">
        <el-button size="small" type="primary" icon="plus" @click="openDialog">{{ t('cableManage.formButtonNew')
          }}</el-button>
        <el-button size="small" type="primary" icon="upload" @click="importDialogVisible = true">{{
          t('cableManage.buttonImport')
        }}</el-button>
        <el-button size="small" type="primary" icon="download" @click="exportCables">{{ t('cableManage.buttonExport')
          }}</el-button>
      </div>
      <el-table ref="multipleTable" style="width: 100%;" tooltip-effect="dark" :data="tableData" row-key="id"
        @selection-change="handleSelectionChange" height="730">
        <el-table-column align="left" label="ID" prop="id" min-width="40" />
        <el-table-column align="left" :label="t('cableManage.nameOfCable')" min-width="180">
          <template #default="{ row }">
            <el-link @click="jump2cable(row.id)">{{ row.name }}</el-link>
          </template>
        </el-table-column>
        <!--        <el-table-column label="线路代号" prop="abbreviation" />-->
        <el-table-column align="left" :label="t('cableManage.section')" prop="section" min-width="120" />
        <el-table-column align="left" :label="t('cableManage.voltageLevel')" prop="voltage_level" min-width="100" />
        <el-table-column align="left" :label="t('cableManage.province')" prop="provincial" min-width="90" />
        <el-table-column align="left" :label="t('cableManage.city')" prop="city" min-width="70" />
        <!--        <el-table-column align="left" label="长度" prop="length" min-width="70"/>-->
        <!--        <el-table-column align="left" label="电缆段数" prop="linecount" min-width="80"/>-->
        <el-table-column :label="t('cableManage.box')" min-width="300">
          <template #default="scope">
            <DropdownWithTags :data="scope.row.gndboxs" label="name" :numTags="1" itemKey="id">
              <template #tag="{ item }">
                <el-link @click="item.subdevs?.length > 0 ? jump2device(item.subdevs[0].id) : null">
                  <div style="display: flex; align-items: center">
                    <box-icon :id="item.id" size="xs" />
                    {{ item.name }}
                  </div>
                </el-link>
              </template>
              <template #dropdown="{ item }">
                <el-link @click="item.subdevs?.length > 0 ? jump2device(item.subdevs[0].id) : null">
                  <div style="display: flex; align-items: center">
                    <box-icon :id="item.id" size="xs" />
                    {{ item.name }}
                  </div>
                </el-link>
              </template>
            </DropdownWithTags>
            <!--            <el-tag class="tag" v-for="item in scope.row.gndboxs" :key="item.id">-->
            <!--              <div style="display: flex; align-items: center">-->
            <!--                <box-icon :id="item.id" size="xs"/>-->
            <!--                {{ item.name }}-->
            <!--              </div>-->
            <!--            </el-tag>-->
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('cableManage.company')" min-width="180">
          <template #default="scope">{{ store.getters['companies/getById'](scope.row.devCompanyId).name }}</template>
        </el-table-column>
        <el-table-column type="expand" :label="t('cableManage.expand')" width="80">
          <template #default="scope">
            <el-row>
              <el-col :span="1" />
              <el-col :span="6"> {{ t('cableManage.nameOfCable') }}: {{ scope.row.name }}</el-col>
              <el-col :span="6"> {{ t('cableManage.voltageLevel') }}: {{ scope.row.voltage_level }}</el-col>
              <el-col :span="6"> {{ t('cableManage.section') }}: {{ scope.row.section }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1" />
              <el-col :span="6"> {{ t('cableManage.abbreviation') }}: {{ scope.row.abbreviation }}</el-col>
              <el-col :span="6"> {{ t('cableManage.length') }}: {{ scope.row.length }}</el-col>
              <el-col :span="6"> {{ t('cableManage.lineCount') }}: {{ scope.row.linecount }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1" />
              <el-col :span="6">
                {{ t('cableManage.manufacture') }}: {{ store.getters['companies/getById'](scope.row.manufacturerId).name
                }}
              </el-col>
              <el-col :span="6">
                {{ t('cableManage.maintenance') }}: {{ store.getters['companies/getById'](scope.row.maintenanceId).name
                }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="1" />
              <el-col :span="6"> {{ t('cableManage.createdAt') }}: {{ formatDate(scope.row.createdat) }}</el-col>
              <el-col :span="6"> UUID: {{ scope.row.uuid }}</el-col>
              <el-col :span="6"> {{ t('cableManage.comment') }}: {{ scope.row.comment }}</el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="t('cableManage.operations')" min-width="250">
          <template #default="scope">
            <el-button size="small" @click="configCable(scope.row)" icon="setting" plain>{{ t('cableManage.config')
              }}</el-button>
            <el-button plain type="primary" icon="edit" size="small" @click="updateCableFunc(scope.row)">{{
              t('cableManage.edit') }}
            </el-button>
            <el-button plain type="warning" icon="delete" size="small" @click="deleteRow(scope.row)">{{
              t('cableManage.delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="gva-pagination pager">
        <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page="page" :page-size="pageSize"
          :page-sizes="[15, 30, 50, 100]" :total="total" @current-change="handleCurrentChange"
          @size-change="handleSizeChange" />
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible" :before-close="closeDialog"
      :title="isEditing ? t('cableManage.dialogTitleEdit') : t('cableManage.dialogTitleNew')">
      <el-form :model="formData" label-position="top">
        <el-form-item :label="t('cableManage.nameOfCable')">
          <el-input v-model.trim="formData.name" clearable />
        </el-form-item>
        <el-form-item :label="t('cableManage.abbreviation')">
          <el-input v-model.trim="formData.abbreviation" clearable />
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="t('cableManage.length')">
              <el-input v-model.number="formData.length" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="t('cableManage.lineCount')" label-width="120px">
              <el-input v-model.number="formData.linecount" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="t('cableManage.province')">
              <el-select v-model="provinceSelected" value-key="name" clearable filterable
                @change="(val) => onChangeProvince(val)">
                <el-option v-for="p in provinces" :key="p.code" :label="p.name" :value="p" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="t('cableManage.city')" label-width="120px">
              <el-autocomplete v-model="formData.city" clearable :fetch-suggestions="queryCity" />

              <!--              <el-select v-model.number="formData.city" clearable filterable>-->
              <!--                <el-option v-for="p in cities" :key="p.code" :label="p.name" :value="p.name" />-->
              <!--              </el-select>-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="t('cableManage.voltageLevel')">
              <el-input v-model.trim="formData.voltage_level" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="t('cableManage.section')" label-width="120px">
              <el-input v-model.trim="formData.section" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="t('cableManage.maintenance')">
          <el-cascader v-model="formData.maintenanceId" style="width:100%" :options="companyOptions"
            :show-all-levels="false" :disabled="!isSuperAdmin"
            :props="{ multiple: false, checkStrictly: true, label: 'companyName', value: 'companyId', disabled: 'disabled', emitPath: false }"
            :clearable="false" />
        </el-form-item>
        <el-form-item :label="t('cableManage.manufacture')">
          <el-cascader v-model="formData.manufacturerId" style="width:100%" :options="companyOptions"
            :show-all-levels="false" :disabled="!isSuperAdmin"
            :props="{ multiple: false, checkStrictly: true, label: 'companyName', value: 'companyId', disabled: 'disabled', emitPath: false }"
            :clearable="false" />
        </el-form-item>
        <el-form-item :label="t('cableManage.company')">
          <el-cascader v-model="formData.devCompanyId" style="width:100%" :options="companyOptions"
            :show-all-levels="false" :disabled="!isSuperAdmin"
            :props="{ multiple: false, checkStrictly: true, label: 'companyName', value: 'companyId', disabled: 'disabled', emitPath: false }"
            :clearable="false" />
        </el-form-item>
        <el-form-item :label="t('cableManage.comment')">
          <el-input v-model.number="formData.comment" clearable />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">{{ t('cableManage.cancel') }}</el-button>
          <el-button size="small" type="primary" @click="enterDialog">{{ t('cableManage.confirm') }}</el-button>
        </div>
      </template>
    </el-dialog>
    <config-wizard :cable="cablePicked" :show-drawer="showDrawer" @hideDrawer="hideDrawer" />
  </div>
  <import-dialog v-model="importDialogVisible" />
</template>

<script>
export default {
  name: 'Cable'
}
</script>

<script setup>
import {
  removeCable,
  getCable,
  editCable,
  newCable,
} from '@/api/cable'
import { getUserInfo } from '@/api/user'
import { getCompanies } from '@/api/company'

import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'
import { useRouter } from "vue-router";
import DropdownWithTags from '../components/DropdownWithTags'
import { useI18n } from 'vue-i18n'
import { getCompany } from '@/api/company'

import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, watch } from 'vue'
import { onMounted } from 'vue'
import { store } from "@/store";
import { formatDate } from '@/utils/format'
import ConfigWizard from './ConfigWizard.vue'

import { Provinces, Cities } from "@/utils/chinaRegions";
import { csvToObj } from "csv-to-js-parser"
import * as XLSX from 'xlsx'
import ImportDialog from "./ImportDialog.vue"

const { t } = useI18n()
const router = useRouter()
const importDialogVisible = ref(false)
// 线路配置向导
const showDrawer = ref(false)
const cablePicked = ref(0)

function configCable(cable) {
  disableCompany()
  showDrawer.value = true
  cablePicked.value = cable.id
}

function hideDrawer() {
  showDrawer.value = false
}
const companyInfo = ref([]);

const newFormData = {
  name: t('cableManage.newCableNamePlaceholder'),
  length: 800,
  linecount: 10,
  provincial: '',
  city: '',
  devCompanyId: 1,
  comment: '',
  gndboxs: [
    {
      key: '1',
      name: t('cableManage.newBoxNamePlaceholder'),
      subdevs: [],
    },
  ],
  voltage_level: '',
  section: '',
}


//新增还是修改
const isEditing = ref(false)

const formData = ref({ ...newFormData })

const page = ref(1)
const total = ref(0)
const pageSize = ref(15)
const tableData = ref([])
const searchInfo = ref({})
const companyOptions = ref([])
const useSearchInfo = ref(false)
const isAdmin = ref(false)

const onReset = () => {
  useSearchInfo.value = false
  searchInfo.value = {}
  companyInfo.value = []
  getTableData()
}

const onSubmit = () => {
  useSearchInfo.value = true
  getTableData()
}

const handleSizeChange = (val) => {
  pageSize.value = val
  getTableData()
}

const handleCurrentChange = (val) => {
  page.value = val
  getTableData()
}
// 获取公司列表
const getSearchCompanyList = async () => {
  const res = await getCompanies({
    page: 1,
    pageSize: 999
  });
  if (res.code === 0) {
    companyInfo.value = res.data.list;
  }
}
const getTableData = async () => {

  const table = await getCable({
    page: page.value,
    pageSize: pageSize.value,
    ext: true,
    ...searchInfo.value
  })
  if (table.code === 0) {
    tableData.value = table.data.list
    total.value = table.data.total
    page.value = table.data.page
    pageSize.value = table.data.pageSize
  }
  store.state.cables.list = table.data.list
}

const setCompanyOptions = async function () {
  const res = await getCompany({})
  if (res.code === 0) {
    let l = res.data.list
    let d = []
    for (let i of l) {
      // TODO 这里需要扩展公司简称
      d.push(
        {
          companyName: i.name,
          companyId: i.id,
        }
      )
    }
    companyOptions.value = d
  }
}

const isSysAdmin = async () => {
  const user = await getUserInfo()
  if (user.data.userInfo.devCompanyId === 1) {
    isAdmin.value = true
  } else {
    isAdmin.value = false
  }
}

onMounted(async () => {
  await getTableData()
  await setCompanyOptions()
  await getSearchCompanyList()
  await isSysAdmin()
})

watch(importDialogVisible, () => {
  getTableData()
})

const multipleSelection = ref([])

const handleSelectionChange = (val) => {
  multipleSelection.value = val
}

const deleteRow = (row) => {
  ElMessageBox.confirm(t('cableManage.deleteConfirmQuestion'), t('cableManage.deleteConfirmTitle'), {
    confirmButtonText: t('cableManage.confirm'),
    cancelButtonText: t('cableManage.cancel'),
    type: 'warning'
  }).then(() => {
    deleteCableFunc(row)
  })
}


const updateCableFunc = async (row) => {
  disableCompany()
  formData.value = { ...row }
  provinceSelected.value = provinceFromName(row.provincial)
  if (provinceSelected.value) {
    getCities(provinceSelected.value)
  }
  isEditing.value = true
  dialogFormVisible.value = true
}

const deleteCableFunc = async (row) => {
  const res = await removeCable(row.id)
  if (res.code === 0) {
    ElMessage({
      type: 'success',
      message: t('cableManage.deleteSuccess')
    })
    if (tableData.value.length === 1 && page.value > 1) {
      page.value--
    }
    await getTableData()
  }
}

const dialogFormVisible = ref(false)

const openDialog = () => {
  disableCompany()
  if (!isEditing.value) {
    provinceSelected.value = provinceFromName('浙江省')
    formData.value.city = '无锡市'
  }
  formData.value.devCompanyId = store.state.user.userInfo.devCompanyId
  dialogFormVisible.value = true
}

const closeDialog = () => {
  isEditing.value = false
  formData.value = { ...newFormData }
  dialogFormVisible.value = false
}

const enterDialog = async () => {
  let service = newCable
  if (isEditing.value) {
    service = editCable
  }
  service(formData.value)
    .catch((e) => console.log(e))
    .then((res) => {
      if (res.code === 0) {
        ElMessage({
          type: 'success',
          message: t('cableManage.createOrEditSuccess')
        })
        closeDialog()
        getTableData()
        store.dispatch('cables/request', true)
      }
    })
}

const isSuperAdmin = ref(false)

function disableCompany() {
  if (store.state.user.userInfo.authorities) {
    for (let a of store.state.user.userInfo.authorities) {
      if (a.authorityId === '111') {
        isSuperAdmin.value = true
        break
      }
    }
  } else {
    if (store.state.user.userInfo.authority.authorityId === '111') {
      isSuperAdmin.value = true
    }
  }
}

// 导出

const exportTableHeadings = [
  '线路名',
  '线路代号',
  '安装位置区段',
  '电压等级',
  '省份',
  '城市',
  '接地箱',
  '长度',
  '电缆段数',
  '所属公司',
  '生产厂家',
  '运维厂商',
  '备注',
]

function getTableRow(cableId) {
  let cable = store.getters['cables/getById'](cableId)

  let userCompany = store.getters['companies/getById'](cable.devCompanyId),
    keeperCompany = store.getters['companies/getById'](cable.maintenanceId),
    makerCompany = store.getters['companies/getById'](cable.manufacturerId)
  return [
    cable.name,
    cable.abbreviation,
    cable.section,
    cable.voltage_level,
    cable.provincial,
    cable.city,
    cable.gndboxs.map((box) => {
      return box.name
    }).join(','),
    cable.length,
    cable.linecount,
    userCompany.name,
    keeperCompany.name,
    makerCompany.name,
    cable.comment,
    // 排序用
    cable.companyId,
    cable.modelId,
  ]
}

function exportCables() {
  const filename = '线路台账.xlsx',
    sheet_name = 'Sheet1'
  let aoa = [exportTableHeadings],
    colsCount = exportTableHeadings.length
  let cableIds = []

  let cable_list = []
  store.state.cables.list.map((cable) => {
    cable_list = [cable, ...cable_list]
  })
  for (let cable of cable_list) {
    // 如果查询
    if (useSearchInfo.value) {
      if (searchInfo.value.name && cable.name !== searchInfo.value.name) {
        continue
      }
      if (searchInfo.value.companyId && cable.devCompanyId !== searchInfo.value.companyId) {
        continue
      }
    }
    cableIds.push(cable.id)
  }

  for (let id of cableIds) {
    let row = getTableRow(id)
    if (!row || row.length < 1) {
      continue
    }
    aoa.push(row)
  }

  // 排序
  aoa.sort((a, b) => {
    return a[colsCount] - b[colsCount]  // 公司id
      || a[colsCount + 1] - b[colsCount + 1]  // 线路id
  })

  // 删掉排序用的字段
  aoa = aoa.map((el) => {
    return el.slice(0, colsCount)
  })

  // 写文件
  let wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(aoa)
  XLSX.utils.book_append_sheet(wb, ws, sheet_name)
  XLSX.writeFile(wb, filename)
}


// 省市

let provinces = csvToObj(Provinces)
let allCities = csvToObj(Cities)

const cities = ref([])
const provinceSelected = ref({})
function getCities(province) {
  formData.value.provincial = provinceSelected.value?.name
  cities.value = allCities.filter((v) => { return v.provinceCode === province.code })
}

function onChangeProvince(province) {
  getCities(province)
  formData.value.city = cities.value[0]?.name
}

function provinceFromName(name) {
  return provinces.find((value) => {
    return value.name === name
  })
}

const queryCity = (queryStr, cb) => {
  const results = queryStr
    ? cities.value.filter(createFilter(queryStr)).map((v) => { return { value: v.name } })
    : cities.value.map((v) => { return { value: v.name } })
  cb(results)
}

function createFilter(queryStr) {
  return (city) => {
    return city.name.includes(queryStr.trim())
  }
}

function jump2cable(cableId) {
  store.commit('cables/setCurrent', cableId)
  router.push('../cablecenter')
}

function jump2device(deviceId) {
  store.commit('cables/setCurrentDevice', deviceId)
  router.push('../devicecenter')
}

</script>

<style scoped>
.tag {
  color: #cfd3dc;
  background: rgba(76, 175, 180, 0.3);
}

.pager {
  z-index: 99;
}
</style>
