<template>
  <el-drawer v-model="show" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false"
             size="75%" @close="closeDrawer();emits('hideDrawer')">
    <template #header="{ titleId, titleClass }">
      <h4 :id="titleId" :class="titleClass" class="drawerHeader">
        {{ t('configWizard.title', [getCableName(cable)]) }}
      </h4>
      <el-button type="primary" v-if="!dragEnabled" @click="enableDrag">{{ t('configWizard.buttonEnableDrag') }}</el-button>
      <template v-else>
        <el-button type="success" @click="confirmDrag">{{ t('configWizard.dragConfirm') }}</el-button>
        <el-button type="info" @click="cancelDrag">{{ t('configWizard.dragCancel') }}</el-button>
      </template>
      <el-button type="warning" @click="openPresetForm">{{ t('configWizard.buttonPreset') }}</el-button>
      <el-button type="danger" @click="show=false" icon="close">{{ t('configWizard.buttonCloseDialog') }}</el-button>
    </template>
    <div v-loading="loading" class="wizard">
      <draggable class="box-view" v-model="boxList" item-key="id" animation="200"
                 ghost-class="ghost"
                 :disabled="!dragEnabled"
                 @start="drag=true" @end="drag=false">
        <template #item="{element}">
          <el-card class="box" :class="cursor">
            <template #header>
              <div class="box-header">
                <el-link class="box-title" :class="cursor" @click="editBox(element.id)" :disabled="dragEnabled">
                  <box-icon :id="element.id" />
                  {{ element.name }}
                </el-link>
                <el-button @click="deleteSth(element.id)" :disabled="dragEnabled" class="box-setting" icon="delete"/>
              </div>
            </template>
            <el-scrollbar height="350px">
              <el-card v-for="device in element.subdevs" :key="device.id">
                <div class="device-header">
                  <el-link class="device-title" @click="editDevice(device.id)" :disabled="dragEnabled">
                    <device-icon :id="device.id" />
                    {{ device.name }}
                  </el-link>
                  <el-button @click="deleteSth(device.id)" class="box-setting" :disabled="dragEnabled" icon="delete"/>
                </div>
              </el-card>
              <el-card v-show="!dragEnabled" @click="addDevice(element.id)" class="add-device">
                <el-button class="add-device-btn">+</el-button>
              </el-card>
            </el-scrollbar>
          </el-card>
        </template>
        <template #footer>
          <el-card
              role="group"
              key="footer"
              class="box add-box"
              @click="addBox"
              v-show="!dragEnabled"
          >
            <el-button class="add-box-btn">+</el-button>
          </el-card>
        </template>
      </draggable>
    </div>
  </el-drawer>
  <el-dialog v-model="showPresetForm" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <template #header>
      <span v-if="init">{{ t('configWizard.presetsDialogTitleInit') }}</span>
      <span v-else>{{ t('configWizard.presetsDialogTitle') }}</span>
    </template>
    <el-form label-position="top">
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.userCompany') }}
        </template>
        <el-select disabled v-model="companyPresets.user" value-key="name">
          <el-option
              v-for="item in store.state.companies.list"
              :key="item.name"
              :label="item.name"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.maintainerCompany') }}
        </template>
        <el-select v-model="companyPresets.maintainer" value-key="name">
          <template v-for="item in store.state.companies.list" :key="item.name">
            <el-option
                v-if="isMaintainer(item.type)"
                :label="item.name"
                :value="item"
            />
          </template>
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.manufacturerCompany') }}
        </template>
        <el-select v-model="companyPresets.manufacturer" value-key="name">
          <template v-for="item in store.state.companies.list" :key="item.name">
            <el-option
                v-if="isManufacturer(item.type)"
                :label="item.name"
                :value="item"
            />
          </template>
        </el-select>
      </el-form-item>
    </el-form>
    <h3 style="font-style: italic">{{ t('dbDeviceModels.环流监测设备') }}</h3>
    <el-row>
      <el-col :span="6"/>
      <el-col :span="7">{{ t('configWizard.thresholdMin') }}</el-col>
      <el-col :span="7">{{ t('configWizard.thresholdMax') }}</el-col>
    </el-row>
    <template v-for="(cfg, keyword) in datacfgPresets" :key="keyword">
      <el-row v-if="circulationMonitor.includes(keyword)">
        <el-col :span="6">
          <template v-if="store.state.user.lang === 'zh'">{{ cfg.name }}</template>
          <template v-else>{{ keyword }}</template>
        </el-col>
        <el-col :span="7">
          <el-input-number v-model="cfg.lo" controls-position="right" :min="-100" :max="1000"/>
        </el-col>
        <el-col :span="7">
          <el-input-number v-model="cfg.hi" controls-position="right" :min="-100" :max="1000"/>
        </el-col>
      </el-row>
    </template>
    <h3 style="font-style: italic">{{ t('dbDeviceModels.边缘代理设备') }}</h3>
    <el-row>
      <el-col :span="6"/>
      <el-col :span="7">{{ t('configWizard.thresholdMin') }}</el-col>
      <el-col :span="7">{{ t('configWizard.thresholdMax') }}</el-col>
    </el-row>
    <template v-for="(cfg, keyword) in datacfgPresets" :key="keyword">
      <el-row v-if="edgeGateway.includes(keyword)">
        <el-col :span="6">
          <template v-if="store.state.user.lang === 'zh'">{{ cfg.name }}</template>
          <template v-else>{{ keyword }}</template>
        </el-col>
        <el-col :span="7">
          <el-input-number v-model="cfg.lo" controls-position="right" :min="-100" :max="1000"/>
        </el-col>
        <el-col :span="7">
          <el-input-number v-model="cfg.hi" controls-position="right" :min="-100" :max="1000"/>
        </el-col>
      </el-row>
    </template>
    <el-form-item>
      <el-button style="margin-top: 30px;" size="large" type="primary" @click="changePresets">
        {{ t('configWizard.buttonChangePresets') }}
      </el-button>
      <el-button style="margin-top: 30px;" size="large" type="info" @click="cancelPresets">
        {{ t('configWizard.cancel') }}
      </el-button>
    </el-form-item>
  </el-dialog>
  <el-dialog v-model="showBoxForm" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <template #header>
      <span v-if="isAdding">{{ t('configWizard.dialogBoxTitleNew') }}</span>
      <span v-else>{{ t('configWizard.dialogBoxTitleEdit') }}</span>
    </template>
    <el-form label-position="top" v-model="boxForm">
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.formType') }}
        </template>
        <el-select :disabled="!isAdding" v-model="boxForm.model" value-key="name">
          <el-option
              v-for="item in store.getters['models/getGrounds']"
              :key="item.name"
              :label="store.state.user.lang === 'zh' ? item.name : t('dbDeviceModels.'+item.name)"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.formBoxName') }}
        </template>
        <el-input v-model.trim="boxForm.name" clearable/>
      </el-form-item>
      <el-form-item :label="t('configWizard.formLength')">
        <el-input v-model.number="boxForm.length" clearable/>
      </el-form-item>
      <el-form-item :label="t('configWizard.formOperationAt')">
        <el-date-picker v-model="boxForm.operationat" type="date" clearable/>
      </el-form-item>
      <el-form-item :label="t('configWizard.formServiceLife')">
        <el-input v-model.number="boxForm.serviceLife" clearable/>
      </el-form-item>
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.formDeviceCode') }}
        </template>
        <el-input v-model.trim="boxForm.deviceCode" clearable/>
      </el-form-item>
      <el-form-item :label="t('configWizard.formConjunct')">
        <el-input v-model.number="boxForm.conjunct" clearable/>
      </el-form-item>
      <el-form-item :label="t('configWizard.formComment')">
        <el-input v-model.trim="boxForm.comment" clearable/>
      </el-form-item>
      <!--      <el-form-item label="GPS模式">-->
      <!--        <el-select v-model.number="boxForm.gpsmodel">-->
      <!--          <el-option-->
      <!--              v-for="item in store.state.models.gpsModels"-->
      <!--              :key="item.value"-->
      <!--              :label="item.name"-->
      <!--              :value="item.value"/>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="GPS设备">-->
      <!--        <el-input v-model.number="boxForm.gpsDevId" clearable/>-->
      <!--      </el-form-item>-->
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.formLongitude') }}
        </template>
        <el-input v-model.trim="boxForm.longitude" clearable/>
      </el-form-item>
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.formLatitude') }}
        </template>
        <el-input v-model.trim="boxForm.latitude" clearable/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="confirmBox">{{ t('configWizard.confirm') }}</el-button>
        <el-button type="info" @click="showBoxForm=false">{{ t('configWizard.cancel') }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <el-dialog v-model="showDeviceForm" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <template #header>
      <span v-if="isAdding">{{ t('configWizard.dialogDeviceTitleNew') }}</span>
      <span v-else>{{ t('configWizard.dialogDeviceTitleEdit') }}</span>
    </template>
    <el-form label-position="top" v-model="deviceForm">
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.formType') }}
        </template>
        <el-select :disabled="!isAdding" v-model="deviceForm.model" value-key="name"
                   @change="deviceModelChange">
          <el-option
              v-for="item in store.getters['models/getIntelligents']"
              :key="item.name"
              :label="store.state.user.lang === 'zh' ? item.name : t('dbDeviceModels.'+item.name)"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.formDeviceName') }}
        </template>
        <el-input v-model.trim="deviceForm.name" clearable/>
      </el-form-item>
      <el-form-item :label="t('configWizard.formOperationAt')">
        <el-date-picker v-model="deviceForm.operationat" type="date" style="width:100%" clearable/>
      </el-form-item>
      <el-form-item :label="t('configWizard.formServiceLife')">
        <el-input v-model.number="deviceForm.serviceLife" clearable/>
      </el-form-item>
      <el-form-item>
        <template #label>
<!--          <span class="required">*</span>-->
          {{ t('configWizard.formUserId') }}
        </template>
        <el-input v-model.trim="deviceForm.userId" clearable />
      </el-form-item>
      <el-form-item>
        <template #label>
          <span class="required">*</span>
          {{ t('configWizard.formDeviceCode') }}
        </template>
        <el-input v-model.trim="deviceForm.deviceCode" clearable />
      </el-form-item>
      <el-form-item :label="t('configWizard.formComment')">
        <el-input v-model.trim="deviceForm.comment" clearable/>
      </el-form-item>
      <el-form-item :label="t('configWizard.formGpsModel')">
        <el-select v-model="deviceForm.gpsmodel">
          <el-option
              v-for="item in store.state.models.gpsModels"
              :key="item.value"
              :label="store.state.user.lang === 'zh' ? item.name : t('dbGpsModels.'+item.name)"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('configWizard.formLongitude')">
        <el-input v-model.trim="deviceForm.longitude" clearable/>
      </el-form-item>
      <el-form-item :label="t('configWizard.formLatitude')">
        <el-input v-model.trim="deviceForm.latitude" clearable/>
      </el-form-item>
      <div class="cfg-form">
        <el-checkbox v-for="(cfg, keyword) in deviceCfgForm" :key="keyword" v-model="cfg.enabled"
                     :label="store.state.user.lang === 'zh' ? cfg.name : keyword" />
      </div>
      <el-form-item>
        <el-button type="primary" @click="confirmDevice">{{ t('configWizard.confirm') }}</el-button>
        <el-button type="info" @click="showDeviceForm=false">{{ t('configWizard.cancel') }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <el-dialog v-model="showConfirmation" :close-on-click-modal="false" :close-on-press-escape="false"
             :show-close="false">
    <h4>{{ t('configWizard.dialogExitTitle') }}</h4>
    <el-button type="danger" @click="closeDrawer">{{ t('configWizard.dialogExitConfirm') }}</el-button>
    <el-button type="info" @click="showConfirmation=false">{{ t('configWizard.cancel') }}</el-button>
  </el-dialog>
  <el-dialog v-model="showDeleteConfirm" :close-on-click-modal="false" :close-on-press-escape="false"
             :show-close="false">
    <div>{{ t('configWizard.dialogDeleteTitle', [something2delete.name]) }}</div>
    <el-button type="danger" @click="deleteSthDone">{{ t('configWizard.dialogDeleteConfirm') }}</el-button>
    <el-button type="info" @click="showDeleteConfirm=false">{{ t('configWizard.cancel') }}</el-button>
  </el-dialog>
</template>

<script setup>
import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'
import DeviceIcon from '@/components/pmsIcon/DeviceIcon.vue'
import { getCableName } from "@/utils/cableNameGen"
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const circulationMonitor = ['earthCur', 'earthVol', 'runCur', 'loadRatio', 'temp']

const edgeGateway = ['batVol', 'temprature', 'humidity', 'simImsi', 'simRssi', 'chassisDoor', 'chassisSmoke',
  'chassisTilt', 'chassisShock', 'gpsEn', 'gpsLongitude', 'gpsLatitude', 'gpsState']


const step = 10

const init = ref(false)

import {ElMessage} from "element-plus";

import draggable from 'vuedraggable'

import {updateDevice as updateService, addDevice as addService, deleteDevice} from "@/api/devices";

import {addCfg, updateCfg} from "@/api/datacfg";

import {ref, watch} from "vue";

import {useStore} from 'vuex';

import {parseTypeCode} from '@/utils/companyCode'

const loading = ref(false)

const show = ref(false);

const store = useStore()

const drag = ref(false)

const dragEnabled = ref(false)

let backup

const cursor = ref('')

function enableDrag() {
  backup = boxList.value
  dragEnabled.value = true
  cursor.value = 'cursor-move'
}

async function confirmDrag() {
  loading.value = true
  let order = []
  for (let i of backup) {
    order.push(i.id)
  }
  for (let i = 0; i < boxList.value.length; i++) {
    let box = boxList.value[i]
    if (order.indexOf(box.id) !== i) {
      if (i === 0) {
        box.queue = -1
      } else {
        box.queue = i * step
      }
      await updateService({...box, model: undefined, subdevs: undefined})
    }
  }
  await store.dispatch('cables/request', true)
      .then(() => {
        dragEnabled.value = false
        cursor.value = ''
        update()
        loading.value = false
      })
}

function cancelDrag() {
  boxList.value = backup
  dragEnabled.value = false
  cursor.value = ''
}

const cable = ref({})

const boxList = ref([])

const props = defineProps({
  cable: {
    type: Number,
    default: 0,
  },
  showDrawer: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(
    ['hideDrawer']
)

async function update() {
  cable.value = store.getters['cables/getById'](props.cable)
  if (!cable.value.gndboxs || cable.value.gndboxs.length < 1) {
    cable.value.gndboxs = []
  }
  let b = [...cable.value.gndboxs]
  b.sort((a, b) => a.queue - b.queue)
  boxList.value = b
  let u = store.getters['companies/getById'](cable.value.devCompanyId)
  // let mt = store.getters['companies/getById'](cable.value.maintenanceId)
  if (
      !store.state.presets[cable.value.id] ||
      Object.keys(store.state.presets[cable.value.id]).length < 1
  ) {
    if (b.length > 0) {
      await store.dispatch('presets/init', cable.value.id)
      // 找回线路通用属性
      let hasCompany = false,
          hasCfg = []
      for (let i = b.length - 1; i > -1; i--) {
        if (!hasCompany) {
          // 设置 companyPresets
          if (b[i].companyId && b[i].maintenanceId && b[i].manufacturerId) {
            let mt = store.getters['companies/getById'](b[i].maintenanceId)
            let mf = store.getters['companies/getById'](b[i].manufacturerId)
            store.commit('presets/setCompany',
                {
                  cableId: cable.value.id,
                  data: {
                    user: {id: u.id, name: u.name},
                    maintainer: {id: mt.id, name: mt.name},
                    manufacturer: {id: mf.id, name: mf.name},
                  }
                }
            )
            hasCompany = true
          }
        }
        if (hasCfg.length < 5) {
          // 设置 datacfgPresets
          if (b[i].subdevs && b[i].subdevs.length > 0) {
            for (let device of b[i].subdevs) {
              for (let datacfg of device.devCfgs) {
                if (datacfg.devDataUnitId < 16) {
                  let cfgName = datacfg.dataUnit?.keyword?.slice(0, -1)
                  if (!cfgName) {
                    continue
                  }
                  if (hasCfg.indexOf(cfgName) < 0) {
                    store.commit('presets/setCfg', {
                      cableId: cable.value.id,
                      [cfgName]: {
                        name: datacfg.dataUnit.name.slice(2),
                        lo: datacfg.thresholdLow,
                        hi: datacfg.thresholdErr
                      }
                    })
                    hasCfg.push(cfgName)
                  }
                }
              }
            }
          }
        }
      }
    } else {
      // 既没有 preset 也没有 box 即是新线路
      init.value = true
      await store.dispatch('presets/init', cable.value.id)
    }
  }
  if (!store.state.presets[cable.value.id] ||
      Object.keys(store.state.presets[cable.value.id]).length < 1 ||
      isCompanyPresetUndone(store.state.presets[cable.value.id].company)) {
    // preset 里面的公司信息有缺失，也需要当作新线路
    init.value = true
  }
  if (!store.state.presets[cable.value.id].cfg ||
      Object.keys(store.state.presets[cable.value.id].cfg).length < 1
  ) {
    await store.dispatch('presets/initCfg', {cableId: cable.value.id})
  }
  companyPresets.value = store.state.presets[cable.value.id].company
  companyPresets.value.user = {id: u.id, name: u.name}
  datacfgPresets.value = store.state.presets[cable.value.id].cfg
  show.value = props.showDrawer
  if (init.value) {
    showPresetForm.value = true
  }
}

function isCompanyPresetUndone(p) {
  if (!p || !p.user || !p.maintainer || !p.manufacturer ||
      Object.keys(p.user) < 1 ||
      Object.keys(p.maintainer) < 1 ||
      Object.keys(p.manufacturer) < 1) {
    return true
  } else {
    return false
  }
}

watch(
    () => props.showDrawer,
    (show) => {
      if (show) {
        update()
      }
    })

// 表单
const isAdding = ref(false)
const showBoxForm = ref(false)
const showDeviceForm = ref(false)
const showPresetForm = ref(false)
const showConfirmation = ref(false)


const companyPresets = ref({})
const datacfgPresets = ref({})

const boxForm = ref({})
const deviceForm = ref({})
let boxBackup = {},
    deviceBackup = {}

async function closeDrawer() {
  show.value = false
  showConfirmation.value = false
  showPresetForm.value = false
  init.value = false
  await restoreCompanyPresets()
  await restoreCfgPresets()
}

function companyIdDiff(a, b) {
  if (a.companyId !== b.companyId || a.manufacturerId !== b.manufacturerId || a.maintenanceId !== b.maintenanceId) {
    return true
  }
}

async function applyCompanies() {
  let companyIds = {
    companyId: companyPresets.value.user.id,
    manufacturerId: companyPresets.value.manufacturer.id,
    maintenanceId: companyPresets.value.maintainer.id,
  }
  for (let box of boxList.value) {
    for (let device of box.subdevs) {
      if (!companyIdDiff(device, companyIds)) {
        continue
      }
      await updateService({
        ...device, ...companyIds,
        model: undefined, subdevs: undefined, devCfgs: undefined
      })
    }
    if (!companyIdDiff(box, companyIds)) {
      continue
    }
    await updateService({
      ...box, ...companyIds,
      model: undefined, subdevs: undefined, devCfgs: undefined
    })
  }

}

async function applyDatacfgs() {
  for (let box of boxList.value) {
    for (let device of box.subdevs) {
      // 如果边缘代理设备或环流监测设备没有任何 cfg, 为其配置默认的值
      if ([9, 10].includes(device.modelId) && (!device.devCfgs || device.devCfgs.length < 1)) {
        let cfgItems = []
        let cfgs2post = []
        const ABC = ['A', 'B', 'C']
        if (device.modelId === 10) {
          if (box.modelId === 6) {
            cfgItems = ABC.map(v => 'earthCur' + v)
          } else if (box.modelId === 7) {
            cfgItems = [
              ...ABC.map(v => 'earthCur' + v),
              ...ABC.map(v => 'earthVol' + v),
            ]
          } else if (box.modelId === 8) {
            cfgItems = ABC.map(v => 'earthVol' + v)
          }
        } else if (device.modelId === 9) {
          cfgItems = ['batVol', 'gpsLongitude', 'gpsLatitude', 'simRssi', 'simIccid']
        }

        for (let cfgItem of cfgItems) {
          let preset = datacfgPresets.value[cfgItem]
          if (!preset) {
            preset = datacfgPresets.value[cfgItem.slice(0, cfgItem.length - 1)]
          }
          if (!preset) {
            preset = {lo: 0, hi: 50}
          }
          let dataUnitId = store.getters['models/getById'](device.modelId).dataFmt.units.find(v => v.keyword === cfgItem).id
          let c = getCfgDataItem(device.id, dataUnitId, preset)
          if (device.modelId === 9) {
            c.alertGrade = 0
          }
          cfgs2post.push(c)
        }
        if (cfgs2post.length > 0) {
          await addCfg({id: device.id, data: cfgs2post})
        }
      }

      let data = []
      for (let cfg of device.devCfgs) {
        if (cfg.devDataUnitId > 15) {
          continue
        }
        let d = {hi: 50, lo: 0}
        switch (cfg.devDataUnitId % 5) {
          case 1:
            d = datacfgPresets.value.earthCur
            break
          case 2:
            d = datacfgPresets.value.runCur
            break
          case 3:
            d = datacfgPresets.value.loadRatio
            break
          case 4:
            d = datacfgPresets.value.earthVol
            break
          case 0:
            d = datacfgPresets.value.temp
        }
        if (cfg.thresholdLow === d.lo && cfg.thresholdErr === d.hi) {
          continue
        }
        cfg.thresholdLow = d.lo
        cfg.thresholdErr = d.hi
        data.push({...cfg, dataUnit: undefined})
      }
      if (data.length < 1) {
        continue
      }
      await updateCfg({
        id: device.id,
        data
      })
    }
  }
}

let cfgPresetBackup,
    companyPresetBackup

function openPresetForm() {
  cfgPresetBackup = JSON.stringify(datacfgPresets.value)
  companyPresetBackup = JSON.stringify(companyPresets.value)
  showPresetForm.value = true
}

async function changePresets() {
  if (
      Object.keys(companyPresets.value.user).length < 1
  ) {
    ElMessage({
      message: t('configWizard.presetValidateCompany'),
      type: 'error',
      showClose: true,
    })
    return
  }
  if (
      Object.keys(companyPresets.value.maintainer).length < 1
  ) {
    ElMessage({
      message: t('configWizard.presetValidateMaintainer'),
      type: 'error',
      showClose: true,
    })
    return
  }
  if (
      Object.keys(companyPresets.value.manufacturer).length < 1
  ) {
    ElMessage({
      message: t('configWizard.presetValidateManufacturer'),
      type: 'error',
      showClose: true,
    })
    showPresetForm.value = true
    return
  }
  loading.value = true
  showPresetForm.value = false
  await store.dispatch('presets/update', cable.value.id, companyPresets.value, datacfgPresets.value)
  await applyCompanies()
  await applyDatacfgs()
  init.value = false
  await store.dispatch('cables/request', true)
      .then(() => {
        update()
        loading.value = false
      })
}

async function cancelPresets() {
  if (isCompanyPresetUndone(companyPresets.value)) {
    showConfirmation.value = true
    return
  }
  if (companyPresetBackup === undefined) {
    await restoreCompanyPresets()
  } else {
    companyPresets.value = JSON.parse(companyPresetBackup)
  }
  if (cfgPresetBackup === undefined) {
    await restoreCfgPresets()
  } else {
    datacfgPresets.value = JSON.parse(cfgPresetBackup)
  }
  showPresetForm.value = false
}

function editBox(id) {
  isAdding.value = false
  let box = store.getters['cables/getDeviceById'](id)
  boxBackup = {...box}
  boxForm.value = {...box}
  showBoxForm.value = true
}

function addBox() {
  isAdding.value = true
  boxForm.value = {
    length: 300,
    // 宜兴市坐标
    longitude: "119.823407",
    latitude: "31.340637",
  }
  showBoxForm.value = true
}

async function confirmBox() {
  if (!boxForm.value.model) {
    ElMessage({
      message: t('configWizard.formBoxValidateModel'),
      type: 'error',
      showClose: true,
    })
    return
  }
  if (!boxForm.value.name) {
    ElMessage({
      message: t('configWizard.formBoxValidateName'),
      type: 'error',
      showClose: true,
    })
    return
  }
  if (!boxForm.value.longitude) {
    ElMessage({
      message: t('configWizard.formBoxValidateLongitude'),
      type: 'error',
      showClose: true,
    })
    return
  }
  if (!boxForm.value.latitude) {
    ElMessage({
      message: t('configWizard.formBoxValidateLatitude'),
      type: 'error',
      showClose: true,
    })
    return
  }
  loading.value = true
  showBoxForm.value = false
  let companyIds = {
    companyId: companyPresets.value.user.id,
    manufacturerId: companyPresets.value.manufacturer.id,
    maintenanceId: companyPresets.value.maintainer.id,
  }
  let service = updateService
  let data = {
    ...boxForm.value, ...companyIds,
    model: undefined, subdevs: undefined, devCfgs: undefined
  }
  if (isAdding.value) {
    service = addService
    if (boxList.value.length > 1) {
      data.queue = boxList.value[boxList.value.length - 1].queue += step
    } else if (boxList.value.length === 1) {
      data.queue = step
    } else {
      data.queue = -1
    }
    data.gpsmodel = 3
    // 接地箱的 GPS 模式默认是 3 从其他设备获取
    data.enable = true
    data.cableId = cable.value.id
    data.modelId = boxForm.value.model.id
  }
  await service(data)
  await store.dispatch('cables/request', true)
      .then(() => {
        update()
        loading.value = false
      })
}

const deviceCfgForm = ref({})

function deviceModelChange() {
  deviceCfgForm.value = {}
  if (deviceForm.value.model.id === 9) {
    // 边缘代理设备
    // 默认使能: 电池电压 sim卡IMSI码 无线信号强度 经度 纬度
    getNormalDeviceCfgs([16, 19, 20, 26, 27])
    // 默认 gps 自动获取
    deviceForm.value.gpsmodel = 2
  } else if (deviceForm.value.model.id === 10) {
    // 环流检测设备
    getDevice10Cfgs()
    // 默认 gps 无效
    deviceForm.value.gpsmodel = 0
  } else if (deviceForm.value.model.id === 11) {
    // 局放
    getDevice11Cfgs()
  } else {
    getNormalDeviceCfgs()
  }
}

function getNormalDeviceCfgs(defaultEnables) {
  let isEnabled = {}
  if (!isAdding.value) {
    for (let cfg of deviceForm.value.devCfgs) {
      if (cfg.enable) {
        isEnabled[cfg.devDataUnitId] = true
      }
    }
  }
  if (defaultEnables === undefined) {
    defaultEnables = []
  }
  if (!deviceForm.value.model.dataFmt || !deviceForm.value.model.dataFmt.units) {
    return
  }
  for (let fmt of deviceForm.value.model.dataFmt.units) {
    let enabled = false
    if (isAdding.value) {
      if (defaultEnables.includes(fmt.id)) {
        enabled = true
      }
    } else {
      enabled = !!isEnabled[fmt.id]
    }
    deviceCfgForm.value[fmt.keyword] = {
      id: [fmt.id],
      name: fmt.name,
      enabled,
    }
  }
}

function getDevice10Cfgs() {
  // 环流检测设备
  let isEnabled = {}
  if (!isAdding.value) {
    // 如果是编辑设备 那么读取设备自身的使能配置
    for (let cfg of deviceForm.value.devCfgs) {
      if (cfg.enable) {
        isEnabled[cfg.devDataUnitId] = true
      }
    }
  }
  for (let fmt of deviceForm.value.model.dataFmt.units) {
    if (fmt.id > 5) {
      continue
    }
    let enabled = false
    if (isAdding.value) {
      // 如果是新建设备 那么根据接地箱类型设置默认使能
      let boxModelId = store.getters['cables/getDeviceById'](deviceInWhichBox).modelId
      if ((fmt.id === 1 && (boxModelId === 6 || boxModelId === 7)) ||
          (fmt.id === 4 && (boxModelId === 8 || boxModelId === 7))) {
        enabled = true
      }
    } else {
      // 否则读取设备自身的使能配置
      enabled = !!isEnabled[fmt.id]
    }
    deviceCfgForm.value[fmt.keyword.slice(0, -1)] = {
      id: [fmt.id, fmt.id + 5, fmt.id + 10],
      name: fmt.name.slice(2),
      enabled,
    }
  }
}

function getDevice11Cfgs() {
  // 局放
  let isEnabled = {}
  if (!isAdding.value) {
    for (let cfg of deviceForm.value.devCfgs) {
      if (cfg.enable) {
        isEnabled[cfg.devDataUnitId] = true
      }
    }
  }
  for (let fmt of deviceForm.value.model.dataFmt.units) {
    if (fmt.id < 29 || fmt.id > 35) {
      continue
    }
    let enabled = false
    enabled = !!isEnabled[fmt.id]
    deviceCfgForm.value[fmt.keyword.slice(0, -1)] = {
      id: [fmt.id, fmt.id + 7, fmt.id + 14],
      name: fmt.name.slice(2),
      enabled,
    }
  }
}

function editDevice(id) {
  deviceCfgForm.value = {}
  isAdding.value = false
  let device = store.getters['cables/getDeviceById'](id)
  deviceBackup = {...device}
  deviceForm.value = {...device}
  if (deviceForm.value.model.id === 10) {
    getDevice10Cfgs()
  } else if (deviceForm.value.model.id === 11) {
    getDevice11Cfgs()
  } else {
    getNormalDeviceCfgs()
  }
  showDeviceForm.value = true
}

let deviceInWhichBox = 0

function addDevice(boxId) {
  deviceCfgForm.value = {}
  deviceInWhichBox = boxId
  isAdding.value = true
  deviceForm.value = {}
  showDeviceForm.value = true
}

function getCfgDataItem(deviceId, dataUnitId, presetItem) {
  return {
    alertGrade: 3,
    devDeviceID: deviceId,
    devDataUnitId: dataUnitId,
    enable: true,
    thresholdErr: presetItem.hi,
    thresholdLow: presetItem.lo,
  }
}

async function confirmDeviceCfg(deviceId) {
  if (!isAdding.value && !deviceId) {
    deviceId = deviceForm.value.id
  }
  if (deviceId === undefined) {
    return
  }
  let cfgStatusQuo = {},
      index = 0
  if (!isAdding.value && deviceForm.value.devCfgs) {
    for (let cfg of deviceForm.value.devCfgs) {
      if (cfg.enable) {
        cfgStatusQuo[cfg.devDataUnitId] = {enabled: true, index}
      }
      index += 1
    }
  }
  let cfgs2post = [],
      cfgs2patch = []
  for (let k in deviceCfgForm.value) {
    let fmt = deviceCfgForm.value[k]
    if (fmt.enabled) {
      let preset = {lo: 0, hi: 50}
      if (datacfgPresets.value[k] !== undefined) {
        preset = datacfgPresets.value[k]
      }
      for (let id of fmt.id) {
        if (cfgStatusQuo[id] === undefined) {
          let c = getCfgDataItem(deviceId, id, preset)
          if (deviceForm.value.model.id === 9) {
            c.alertGrade = 0
          } // 边缘代理设备
          cfgs2post.push(c)
        } else if (cfgStatusQuo[id].enabled === false) {
          cfgs2patch.push({...deviceForm.value.devCfgs[cfgStatusQuo[id].index], enable: true, dataUnit: undefined})
        }
      }
    } else {
      for (let id of fmt.id) {
        if (cfgStatusQuo[id] && cfgStatusQuo[id].enabled) {
          cfgs2patch.push({...deviceForm.value.devCfgs[cfgStatusQuo[id].index], enable: false, dataUnit: undefined})
        }
      }
    }
  }
  if (cfgs2patch.length > 0) {
    await updateCfg({id: deviceId, data: cfgs2patch})
  }
  if (cfgs2post.length > 0) {
    await addCfg({id: deviceId, data: cfgs2post})
  }
}

async function confirmDevice() {
  if (!deviceForm.value.model) {
    ElMessage({
      message: t('configWizard.formDeviceValidateModel'),
      type: 'error',
      showClose: true,
    })
    return
  }
  if (!deviceForm.value.name) {
    ElMessage({
      message: t('configWizard.formDeviceValidateName'),
      type: 'error',
      showClose: true,
    })
    return
  }
  // if (!deviceForm.value.userId) {
  //   ElMessage({
  //     message: '请输入设备识别码',
  //     type: 'error',
  //     showClose: true,
  //   })
  //   return
  // }
  loading.value = true
  showDeviceForm.value = false
  let companyIds = {
    companyId: companyPresets.value.user.id,
    manufacturerId: companyPresets.value.manufacturer.id,
    maintenanceId: companyPresets.value.maintainer.id,
  }
  let service = updateService
  let data = {
    ...deviceForm.value, ...companyIds,
    model: undefined, subdevs: undefined, devCfgs: undefined
  }
  if (isAdding.value) {
    service = addService
    data.enable = true
    data.boxId = deviceInWhichBox
    data.modelId = deviceForm.value.model.id
  }
  let deviceId = deviceForm.value.id
  await service(data)
      .then((r) => {
        if (isAdding.value) {
          if (r.code === 0) {
            deviceId = r.data.id
          }
        }
      })
  await confirmDeviceCfg(deviceId)
  await store.dispatch('cables/request', true)
      .then(() => {
        update()
        loading.value = false
      })
}

async function restoreCompanyPresets() {
  await store.dispatch('presets/emptyCompanyPreset')
      .then((r) => {
        companyPresets.value = r
      })
}

async function restoreCfgPresets() {
  await store.dispatch('presets/emptyDatacfgPreset')
      .then((r) => {
        datacfgPresets.value = r
      })
}

const showDeleteConfirm = ref(false)

const something2delete = ref('')

function deleteSth(id) {
  let d = store.getters['cables/getDeviceById'](id)
  if ([6, 7, 8].includes(d.modelId)) {
    if (d.subdevs && d.subdevs.length > 0) {
      ElMessage({
        message: t('configWizard.messageDeleteError'),
        type: 'error',
        showClose: true,
      })
      return
    }
  }
  something2delete.value = d
  showDeleteConfirm.value = true
}

async function deleteSthDone() {
  loading.value = true
  await deleteDevice(something2delete.value.id)
  await store.dispatch('cables/request', true)
      .then(() => {
        update()
        showDeleteConfirm.value = false
        loading.value = false
      })
}

function isMaintainer(code) {
  return parseTypeCode(code).includes(1)
}

function isManufacturer(code) {
  return parseTypeCode(code).includes(2)
}

</script>

<style scoped>

.box-view {
  /*width: 1000px;*/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}

.box {
  height: 450px;
  width: 300px;
  margin: 0 20px 30px 20px;
}

.ghost {
  opacity: 0.5;
  background: dimgrey;
  border: 2px dotted whitesmoke;
}

.box-header {
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.box-setting {
  border-radius: 40px;
  padding: 8px;
  border: none;
  font-size: 20px;
}

.add-box {
  display: flex;
  /*flex-direction: column;*/
  align-items: center;
  justify-content: center;
}

.add-box-btn {
  color: beige;
  font-size: 100px;
  background: none;
  border: none;
}

.device-header {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-device {
  text-align: center;
}

.add-device-btn {
  color: beige;
  font-size: 40px;
  background: none;
  border: none;
}

.box-title {
  font-size: 20px;
  line-height: 30px;
}

.device-title {
  font-size: 18px;
}

.required {
  color: red;
}

.cursor-move {
  cursor: move;
}

.cfg-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
