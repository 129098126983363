<template>
  <el-drawer v-model="dialogVisible" :size="drawerSize" :before-close="handleUploadDialogClose">
    <div>
      <el-steps :active="activeStep" finish-status="success">
        <el-step title="上传" />
        <el-step title="检查" />
        <el-step title="导入" />
      </el-steps>

      <!--    第一步：上传-->
      <template v-if="activeStep === 0">
        <el-upload drag multiple :limit="1" :auto-upload="false" accept="xlsx" :on-exceed="handleExceed"
          v-model:file-list="uploadFileList" ref="uploader">
          <el-icon class="el-icon--upload" id="fileInput">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text"><em>点击上传</em></div>
        </el-upload>
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <el-link href="/files/线路台账模板.xlsx" target="_blank">下载模板</el-link>
          <el-button type="primary" @click="importFromXlsx" :disabled="uploadFileList.length < 1">上传
          </el-button>
        </div>
      </template>

      <!--    第二步：检查-->
      <template v-if="activeStep === 1">
        <p>
          上传完成。 有效数据 {{ validRowsCount }} 行。
          <template v-if="invalidRowList.length > 0">
            第
            {{
              invalidRowList.length > 3
                ? invalidRowList.slice(0, 4).join('、')
                : invalidRowList.join('、')
            }}
            行等 {{ invalidRowList.length }} 行无效。
          </template>
          <template v-if="validRowsCount === 0">请检查您的文件后重新上传。</template>
        </p>
        <div style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
          ">
          <el-button @click="() => activeStep -= 1" :icon="Back">上一步</el-button>
          <el-button type="primary" :icon="Right" :disabled="validRowsCount === 0" @click="goImport">继续导入
          </el-button>
        </div>
      </template>

      <!--    第三步：导入-->
      <template v-if="activeStep > 1">
        <el-divider />
        <h2>批量创建任务</h2>
        <el-progress :text-inside="true" :stroke-width="24" :percentage="Math.floor((counts.cableCreate.success + counts.cableCreate.success)) /
          (counts.cableCreate.total + counts.cableCreate.total) * 100" status="success" />
        <p>总任务：{{ counts.cableCreate.total + counts.cableCreate.total }}</p>
        <p>成功：{{ counts.cableCreate.success + counts.cableCreate.success }}</p>
        <p>失败：{{ counts.cableCreate.failure + counts.cableCreate.failure }}</p>
        <template v-if="needUpdate">
          <el-divider />

          <h2>批量更新任务</h2>
          <el-progress :text-inside="true" :stroke-width="24" :percentage="Math.floor((counts.cableUpdate.success + counts.cableUpdate.success) /
            (counts.cableUpdate.total + counts.cableUpdate.total)) * 100" status="success" />
          <p>总任务：{{ counts.cableUpdate.total + counts.cableUpdate.total }}</p>
          <p>成功：{{ counts.cableUpdate.success + counts.cableUpdate.success }}</p>
          <p>失败：{{ counts.cableUpdate.failure + counts.cableUpdate.failure }}</p>
        </template>
        <div style="display: flex; flex-direction: row; justify-content: flex-end">
          <el-button type="success" :disabled="activeStep < 3" @click="exit()">完成</el-button>
        </div>
      </template>

      <!--    预览-->
      <template v-if="activeStep > 0">
        <el-button @click="togglePreview">预览</el-button>
        <el-table v-if="previewVisible" :data="cable_list" max-height="650">
          <el-table-column label="行号" prop="row" width="60" />
          <el-table-column label="线路代号" prop="abbreviation" />
          <el-table-column label="线路名称" prop="name" />
          <el-table-column label="省" prop="provincial" />
          <el-table-column label="市" prop="city" />
          <el-table-column label="线路总长度" prop="length" />
          <el-table-column label="电缆段数" prop="linecount" />
          <el-table-column label="电压等级" prop="voltage_level" />
          <el-table-column label="安装位置区段" prop="section" />
          <el-table-column label="所属公司" prop="companyName" />
          <el-table-column label="公司代号" prop="companyAbbr" />
          <el-table-column label="导入状态">
            <template #default="{ row }">
              <div :set="(stat = getRowStatus(row.status))" style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                ">
                <el-result style="transform: scale(0.3); padding: 0" :icon="stat[0]" />
                <span>{{ stat[1] }}
                  <template v-if="stat[1].includes('数据已存在') && activeStep > 1">，可以<a
                      style="color: #5cd9e8; cursor: pointer" @click="updateSingleRow(row)">更新</a></template></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </el-drawer>
</template>

<script setup>
import { defineEmits, defineProps, ref, computed, reactive } from 'vue'
import * as XLSX from 'xlsx'
import { ElMessageBox, genFileId } from 'element-plus'
import { newCable, editCable } from '@/api/cable'
import { useStore } from 'vuex'
import { Back, Right } from '@element-plus/icons-vue'
import { getCompany } from '@/api/company'
import { onMounted } from 'vue'
const props = defineProps({
  modelValue: {
    type: Boolean,
  },
})
const store = useStore()
const emit = defineEmits(['update:modelValue'])

function togglePreview() {
  previewVisible.value = !previewVisible.value
  if (previewVisible.value) {
    drawerSize.value = '95%'
  } else {
    drawerSize.value = '50%'
  }
}

const dialogVisible = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  },
})

const companyInfo = ref();

// upload 组件的属性
const uploader = ref() // upload 组件的 ref
const uploadFileList = ref([])
// 当前上传进度
const activeStep = ref(0)

const cable_list = ref([])  // 产品代号校验通过的有效行

const validRowsCount = ref(0) // 创建成功的行数
const invalidRowList = ref([])

const previewVisible = ref(false)

const createSuccesses = ref(0)
const needUpdate = ref(false)
const updateSuccesses = ref(0)
const updateFailures = ref(0)
const drawerSize = ref('50%')
let cableCode2idMap = {}

// 线路id: 最大的queue
let queues = {}

// 用来展示进度条
let default_results = {
  total: 0,
  success: 0,
  failure: 0,
}
const counts = reactive({
  cableCreate: { ...default_results },
  cableUpdate: { ...default_results },
})

// 内容: cable_list 里的 index
let cableCreates = []
let cableUpdates = []
function init() {

  drawerSize.value = '50%'

  activeStep.value = 0
  cable_list.value = []
  validRowsCount.value = 0
  invalidRowList.value = []

  previewVisible.value = false

  createSuccesses.value = 0
  needUpdate.value = false

  updateSuccesses.value = 0
  updateFailures.value = 0

  cableCode2idMap = {}

  queues = {}

  for (let prop in counts) {
    if (prop.success) {
      prop.success = 0
    }
    if (prop.failure) {
      prop.failure = 0
    }
    if (prop.total) {
      prop.total = 0
    }
  }

  // 清空任务列表
  cableCreates = []
  cableUpdates = []
}

init()
onMounted(async () => {
  await getCompanyInfo()
})

const getCompanyInfo = async () => {
  const company = await getCompany({ id: store.state.user.userInfo.devCompanyId })
  if (company.code === 0) {
    companyInfo.value = company.data
  }
}

// 上传
function handleExceed(files) {
  uploader.value.clearFiles()
  let file = files[0]
  file.uid = genFileId()
  uploader.value.handleStart(file)
}


function importFromXlsx() {

  let reader = new FileReader()
  reader.onload = function (e) {
    let data = XLSX.read(e.target.result, { type: 'binary' })
    processXlsx(data).then(() => {
      activeStep.value = 1
    })
  }
  reader.readAsBinaryString(uploadFileList.value[0].raw)
}

const validCableProps = [
  'abbreviation',
  'name',
  'section',
  'voltage_level',
  'provincial',
  'city',
  'length',
  'linecount',
  'devCompanyId',
]


async function processXlsx(data) {
  // 第一步: 简单处理表格, 忽略填写不全的, 标记有问题的 (没有接地箱类型), 标记已存在的
  // 只读取第一张 sheet
  const sheetName = '线路页'
  let sheet = data.Sheets?.[sheetName]
  if (!sheet) {
    return
  }
  // 长宽
  let [columns, rows] = getSheetDimensions(sheet)
  // 只处理 1000 行
  if (rows > 1000) {
    rows = 1000
  }
  // 判断哪一列是什么数据
  let columnPosMap = {}
  for (let i = 0; i < columns; i++) {
    let letter = String.fromCharCode(65 + i),
      cell = sheet[letter + '1']
    if (!cell) {
      continue
    }
    columnPosMap[cell.v] = letter
  }
  // 分别操作每一行
  let cables = []
  for (let i = 2; i <= rows; i++) {
    let cable = { row: i }
    for (let [k, v] of Object.entries(columnPosMap)) {
      let cell = sheet[v + i]
      if (!cell) {
        continue
      }
      processCell(k, sheet[v + i], cable)
    }
    if (cable.__head && cable.__head.includes('填写要求')) {
      continue
    }
    if (!cable.abbreviation || !cable.name) {
      continue
    }
    cables.push(cable)
  }
  // createIndexList = []
  let invalids = [],
    valids = 0
  for (let [i, c] of cables.entries()) {
    let invalid_type = '',
      invalid = false
    if (!c.abbreviation) {
      invalid = true
      invalid_type = 'invalid_cable_abbreviation'
    } else if (!c.name) {
      invalid = true
      invalid_type = 'invalid_cable_name'
    } else if (!c.companyAbbr) {
      invalid = true
      invalid_type = 'invalid_dev_company'
    }  else if (companyInfo.value.abbreviation&&c.companyAbbr != companyInfo.value.abbreviation && store.state.user.userInfo.devCompanyId != 1) {
      invalid = true
      invalid_type = 'invalid_dev_companyabbr'
    }
    if (invalid) {
      c.status = invalid_type // 标记 这一行有问题
      invalids.push(c.row)
      continue
    }

    c.status = 'to_create' // 标记 这一行待创建
    valids += 1

    // TODO:判断数据是否已存在
    let olds = store.getters['cables/getByAbbreviation']({
      abbreviation: c.abbreviation,
    })

    for (let old of olds) {
      cableCode2idMap[c.abbreviation] = old.id
      c.status = 'has_cable'
    }

    if (c.status === 'to_create') {
      cableCreates.push(i)
    } else if (c.status === 'has_cable') {
      cableUpdates.push(i)
    }
  }

  counts.cableCreate.total = cableCreates.length
  counts.cableUpdate.total = cableUpdates.length
  invalidRowList.value = invalids
  validRowsCount.value = valids
  // device_list.value = devices
  cable_list.value = cables
}

function getSheetDimensions(sheet) {
  let furthestCell = sheet['!ref'].split(':')[1]
  let rowsMatchArray = furthestCell.match(/[0-9]{1,}/),
    colsMatchArray = furthestCell.match(/[A-Z]{1,}/)
  let colsChar = colsMatchArray[0]
  let x = colsChar.charCodeAt(0) - 65 + 1
  // 如果超过了 Z 列
  if (colsChar.length > 1) {
    let firstLetterPos = x,
      secondLetterPos = colsChar.charCodeAt(1) - 65 + 1
    x = firstLetterPos * 26 + secondLetterPos
  }
  // 超过 ZZ 就不管了
  if (colsChar.length > 2) {
    x = 99
  }
  return [x, parseInt(rowsMatchArray[0])]
}

function parseNumber(str) {
  let num = parseInt(str)
  if (isNaN(num)) {
    num = 0
  }
  return num
}


function prepareCableData(row, id) {
  let data = {}
  for (let p of validCableProps) {
    data[p] = row[p]
  }

  row.id = id
  data.id = id
  return data
}

async function _taskRunner(createOrUpdate) {
  let task_list = cableCreates,
    _prepareFunc = prepareCableData,
    data_list = cable_list,
    idMap = cableCode2idMap,
    apiFunc = newCable

  let prepareFunc = _prepareFunc
  if (createOrUpdate === 'update') {
    task_list = cableUpdates
    apiFunc = editCable
    prepareFunc = (row) => {
      let data = _prepareFunc(row, idMap[row.abbreviation])
      return data
    }
  } else {
    prepareFunc = (row) => {
      let data = _prepareFunc(row)
      return data
    }
  }

  let count_prop_str = "cable" + createOrUpdate.slice(0, 1).toUpperCase() + createOrUpdate.slice(1)

  let tasks = []
  if (!task_list) {
    return
  }
  for (let i of task_list) {
    let data = prepareFunc(data_list.value[i])
    tasks.push(apiFunc(data))
  }
  let taskResults = await Promise.all(tasks)
  for (let [i, r] of taskResults.entries()) {
    let cable_list_index = task_list[i]
    if (r.code === 0) {
      cable_list.value[cable_list_index].status = createOrUpdate + 'd'
      counts[count_prop_str].success += 1
      // 如果是 box, 更新 boxCode2idMap
      cableCode2idMap[r.data.abbreviation] = r.data.id
    } else {
      cable_list.value[cable_list_index].status = createOrUpdate + '_error'
      counts[count_prop_str].failure += 1
    }
  }
}


async function goImport() {
  activeStep.value = 2

  await _taskRunner('create')
  await store.dispatch('cables/request', true)

  let rowsNeedUpdate = counts.cableUpdate.total
  if (rowsNeedUpdate < 1) {
    activeStep.value = 3
    return
  }
  try {
    await ElMessageBox({
      title: '警告',
      message: `${rowsNeedUpdate} 行数据已存在，要全部更新吗？`,
      showCancelButton: true,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      distinguishCancelAndClose: true,
      showClose: false,
    })
    activeStep.value = 3
    needUpdate.value = true
    await _taskRunner('update')
  } catch (err) {
    activeStep.value = 3
  }
  store.dispatch('cables/request', true)
}


function processCell(columnName, cell, cable) {
  columnName = columnName.trim()
  let cellValue = String(cell.v).trim()
  switch (columnName) {
    case '序号':  // 填写说明
      cable['__head'] = cellValue
      break
    case '线路代号':
      cable['abbreviation'] = cellValue
      break
    case '线路名称':
      cable['name'] = cellValue
      break
    case '省':
      cable['provincial'] = cellValue
      break
    case '市':
      cable['city'] = cellValue
      break
    case '线路总长度':
      cable['length'] = parseNumber(cellValue)
      break
    case '电缆段数':
      cable['linecount'] = parseNumber(cellValue)
      break
    case '电压等级':
      cable['voltage_level'] = cellValue
      break
    case '安装位置区段':
      cable['section'] = cellValue
      break
    case '所属公司':
      cable['companyName'] = cellValue
      break
    case '公司代号': {
      cable['companyAbbr'] = cellValue
      let company = store.getters['companies/getByAbbr'](cellValue)
      if (company) {
        cable['devCompanyId'] = company.id
      }
      break
    }
  }
}

function handleUploadDialogClose() {
  if (
    activeStep.value > 2 || // 写入完成
    (activeStep.value === 0 && uploadFileList.value.length < 1) // 已上传文件
  ) {
    exit()
    return
  }
  // 正在写入, 不可关闭
  if (activeStep.value === 2) {
    return
  }
  // 其他情况
  ElMessageBox.confirm('确定要关闭对话框吗？您可能需要重新上传文件。')
    .then(() => {
      exit()
    })
    .catch(() => {
      return
    })
}

function exit() {
  init()
  var fileInput = document.getElementById('fileInput');
  if (fileInput) {
    uploader.value.clearFiles()
  }
  dialogVisible.value = false
}

function getRowStatus(status) {
  if (/has_cable.*/.test(status)) {
    return ['warning', `数据已存在`]
  }
  switch (status) {
    case 'to_create':
      return ['info', '待创建']
    case 'created':
      return ['success', '创建成功']
    case 'updated':
      return ['success', '更新成功']
    case 'invalid_cable_abbreviation':
      return ['error', '线路代号无效']
    case 'invalid_cable_name':
      return ['error', '线路名称无效']
    case 'invalid_dev_company':
      return ['error', '所属公司无效']
    case 'invalid_dev_companyabbr':
      return ['error', '请勿导入非本公司数据']
    case 'create_error':
      return ['error', '创建错误']
    case 'update_error':
      return ['error', '更新错误']
    default:
      return ['info', '等待检查']
  }
}

async function updateSingleRow(row) {
  let cables = store.getters['cables/getByAbbreviation']({
    abbreviation: row.abbreviation,
  })
  let cable
 
  for (let c of cables) {
      cable = c
  }

  let tasks = []
  if (!cable) {
    tasks.push(newCable(prepareCableData(row)))
  } else {
    tasks.push(editCable({...prepareCableData(row, cableCode2idMap[row.abbreviation])}))
  }

  let response = await Promise.all(tasks)
  let updateFail = false
  for (let r of response) {
    if (r.code !== 0) {
      updateFail = true
      break
    }
  }
  if (updateFail) {
    row.status = 'update_error'
  } else {
    row.status = 'updated'
  }
}
</script>
