import service from '@/utils/request'

const url = '/api/v1/devDataCfgs/'


export const updateCfg = ({id, data}) => {
  return service({
    url: url + id,
    method: 'patch',
    data,
  })
}

export const addCfg = ({id, data}) => {
  return service({
    url: url + id,
    method: 'post',
    data,
  })
}
